<i18n lang="yaml">
ru:
  menu: 'Меню'
en:
  menu: 'Menu'
</i18n>

<template>
  <div class="header-item">
    <div v-if="naviItem.show_child_pages && naviItem.desktop_show" class="menu menu-c"
         @click="closeTree" v-bind:class="{'show-menu': showMenu}" v-click-outside="closeMenu">
      <span>{{ naviItem.title }}</span>
      <i class="svg-icon arrow"></i>


      <Transition name="fade">
        <div v-if="showMenu" class="header-menu">
          <div>
            <div class="close-menu">
              <div class="circle-back"><i class="svg-icon cross"></i></div>
            </div>
            <div class="pages">
              <template v-for="page in pagesChildren">
                <div>
                  <span v-if="noContent(page) || localePath(`${naviItem.link_page}${page.slug}/`) === $route.path"
                      :class="{'first-lvl': page.children}">{{ page.title }}</span>
                  <nuxt-link v-else 
                    :to="localePath(`${naviItem.link_page}${page.slug}/`)"
                    :class="{'first-lvl': page.children}"
                  >{{ page.title }}</nuxt-link>

                  <div v-if="page.children" class="pages-children">
                    <template v-for="children_page in page.children">
                      <span v-if="localePath(`${naviItem.link_page}${page.slug}/${children_page.slug}/`) === $route.path">
                        {{ children_page.title }}
                      </span>
                      <nuxt-link v-else :to="localePath(`${naviItem.link_page}${page.slug}/${children_page.slug}/`)">
                        {{ children_page.title }}
                      </nuxt-link>
                    </template>
                  </div>
                </div>
              </template>
            </div>

            <div class="applications" v-if="applications.length">
              <a v-for="application in applications" 
                target="_blank" 
                :href="application.link_external"
                :class="{'disabled': !application.link_external}"
              >{{ application.title }}</a>
            </div>
          </div>
        </div>
      </Transition>


    </div>
    <div v-else-if="naviItem.header_submenu.length && naviItem.desktop_show" class="menu menu-c"
         @click="closeTree"
         v-bind:class="{'show-menu': showMenu}" v-click-outside="closeMenu">
      <span>{{ naviItem.title }}</span>
      <i class="svg-icon arrow"></i>
      <Transition name="fade">
        <div v-if="showMenu" class="header-menu">
          <div>
            <site-link v-for="item in naviItem.header_submenu" :key="item.id" v-if="item.desktop_show"
                       :link-item="item"/>
          </div>
        </div>
      </Transition>
    </div>
    <div v-else-if="!naviItem.header_submenu.length && naviItem.desktop_show" class="menu">
      <site-link :link-item="naviItem"/>
    </div>
  </div>


</template>

<script>
import SiteLink from '@/components/site-link';
import {Application} from "@/models/menu";

export default {
  components: {
    SiteLink,
  },
  props: ['naviItem'],
  data() {
    return {
      showMenu: false,
      showMenuMobile: false,
      pagesChildren: [],
      applications: []
    }
  },
  mounted() {
    if (this.naviItem.show_child_pages) {
      this.$axios.$get('/pages/find/', {params: {html_path: this.naviItem.link_page}})
          .then((response) =>
              this.$axios.$get('/menu-child-pages/', {params: {page_id: response.id}})
                  .then((response) => {
                    this.pagesChildren = response
                  })
          )
    }
  },
  async fetch() {
    this.applications = await Application.config({count_loading: true}).get();
  },
  methods: {
    noContent(page){
      const cc = JSON.parse(page.content)
      // console.log('>>>>', page)
      return cc.length === 0 && !page.form
    },
    closeMenu() {
      if (this.showMenu) {
        this.showMenu = !this.showMenu;
      }
    },
    closeMenuMobile() {
      if (this.showMenuMobile) {
        this.showMenuMobile = !this.showMenuMobile;
      }
    },
    closeTree() {
      this.showMenu = !this.showMenu
      this.$emit('close-tree');
    },
    closeTreeMobile() {
      this.showMenuMobile = !this.showMenuMobile
      this.$emit('close-tree');
    },
  },
}
</script>
